import React, { createContext, useState } from 'react';

export const UserContext = createContext();

const Provider = ({ children }) => {

    const verifiedCodes = ["11", "22", "33", "55", "66", "44"]

    const [position, setPosition] = useState("")
    const [doingPosition, setDoingPosition] = useState("")
    const [fileDatas, setFileDatas] = useState({})

    return (
        <UserContext.Provider value={
            {
                verifiedCodes,
                position,setPosition,
                doingPosition, setDoingPosition,
                fileDatas, setFileDatas,
            }}>
            {children}
        </UserContext.Provider>
    );
}

export default Provider;
