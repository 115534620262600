import { useContext } from "react";
import { UserContext } from "../../context/provider";
import { exportToCSV, FileInput } from "../mostlyUsed/mainComponents";

const WarehouseHandling = () => {
  const { fileDatas, setFileDatas, verifiedCodes } = useContext(UserContext);

  const submit = () => {
    let allClearProducts = [];
    let mainResualt = [];
    // const countInd = 26
    // const codeInd = 4
    // const titleInd = 2
    const countInd = 11
    const codeInd = 2
    const titleInd = 4
    for (let i in fileDatas.hesabfa) {
      if (
        i !== "0" &&
        verifiedCodes.includes(fileDatas.hesabfa[i][codeInd]?.substring(0, 2)) &&
        fileDatas.hesabfa[i][codeInd].length === 11
      ) {
        allClearProducts.push({
          count: fileDatas.hesabfa[i][countInd],
          code: fileDatas.hesabfa[i][codeInd],
          title: fileDatas.hesabfa[i][titleInd],
        });
        mainResualt.push({
          count: 0,
          code: fileDatas.hesabfa[i][codeInd],
          title: fileDatas.hesabfa[i][titleInd],
        });
      }
    }
    console.log("allClearProducts : ", allClearProducts);
    console.log("mainResualt : ", mainResualt);
    console.log(fileDatas);
    for (let i in mainResualt) {
      for (let j of fileDatas.anbar) {
        if (mainResualt[i].code === String(j[0])) {
          mainResualt[i].count = mainResualt[i].count + 1;
        }
      }
    }

    console.log("new one : ", mainResualt);

    let conflict = [];
    for (let i in mainResualt) {
      for (let j in allClearProducts) {
        if (
          mainResualt[i]["code"] === allClearProducts[j]["code"] &&
          mainResualt[i]["count"] - allClearProducts[j]["count"] !== 0
        ) {
          conflict.push({
            اختلاف: mainResualt[i]["count"] - allClearProducts[j]["count"],
            "تعداد در انبار": mainResualt[i]["count"],
            "تعداد در سیستم": allClearProducts[j]["count"],
            سایز: String(mainResualt[i]["code"]).substring(9, 11),
            بارکد: mainResualt[i]["code"],
            "نام محصول": mainResualt[i]["title"],
          });
        }
      }
    }
    console.log("conflicts : ", conflict);
    exportToCSV(conflict, null, "final file");
  };

  return (
    <div className="mainComponent">
      <FileInput
        title="فایل اکسل انبار حسابفا"
        fileName="hesabfa"
        contextData={setFileDatas}
      />
      <FileInput
        title="فایل اکسل بارکد های انبار"
        fileName="anbar"
        contextData={setFileDatas}
      />
      <div>
        <button onClick={submit}>تایید</button>
      </div>
    </div>
  );
};

export default WarehouseHandling;
