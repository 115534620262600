import { useContext, useState } from "react";
import { UserContext } from "../../context/provider";
import { exportToCSV, FileInput } from "../mostlyUsed/mainComponents";

const SellData = () => {
  const { fileDatas, setFileDatas, verifiedCodes } = useContext(UserContext);
  const [hasCount, setHasCount] = useState(true)
  const [barcode, setBarcode] = useState(3)
  const [oldTitleInd, setOldTitleInd] = useState(2)
  const [countInd, setCountInd] = useState(11)
  const [sellInd, setSellInd] = useState(5)
  const [backInd, setBackInd] = useState(7)
  

  const submit = () => {
    // const barcode = 3;
    // const oldTitleInd = 2;
    // const countInd = 11;
    // const sellInd = 5;
    // const backInd = 7;
    console.log(fileDatas);
    let mainResualt = [];
    for (let i in fileDatas.hesabfa) {
      if (
        i !== "0" &&
        verifiedCodes.includes(
          fileDatas.hesabfa[i][barcode]?.substring(0, 2)
        ) &&
        fileDatas.hesabfa[i][barcode].length === 11
      ) {
        mainResualt.push({
          count: hasCount ? fileDatas.hesabfa[i][countInd] : fileDatas.hesabfa[i][sellInd] - fileDatas.hesabfa[i][backInd],
          code: fileDatas.hesabfa[i][barcode],
          title: fileDatas.hesabfa[i][oldTitleInd],
        });
      }
    }
    console.log("main resault: ", mainResualt);

    let final = [];
    let pushed = [];
    for (let i in mainResualt) {
      const pureCode = mainResualt[i].code.slice(0, 9);
      if (pushed.includes(pureCode)) {
        for (let g in final) {
          if (pureCode === final[g].code) {
            const size = mainResualt[i].code.substring(9, 11);
            final[g][size] = mainResualt[i].count;
            break;
          }
        }
      } else {
        console.log(mainResualt[i]);
        const size = mainResualt[i].code.substring(9, 11);
        console.log(size);
        pushed.push(pureCode);
        final.push({
          title: mainResualt[i].title,
          code: pureCode,
          25: "",
          26: "",
          27: "",
          28: "",
          29: "",
          30: "",
          31: "",
          32: "",
          33: "",
          34: "",
          35: "",
          36: "",
          37: "",
          38: "",
          39: "",
          40: "",
          41: "",
          42: "",
          43: "",
          44: "",
          45: "",
          46: "",
          47: "",
        });
        final[final.length - 1][size] = mainResualt[i].count;
      }
    }
    let sizeSum = {};
    for (let i in final) {
      // final[i].title = final[i].title.replace(/\s+/g, " ").trim();
      console.log("---------------------");
      for (let j = 30; j < 48; j++) {
        console.log(j, ": ", final[i][j]);
        if (!sizeSum[j]) {
          sizeSum[j] = 0;
        }
        sizeSum[j] += final[i][j] ? final[i][j] : 0;
      }
    }
    final.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    sizeSum = { ...sizeSum, title: "مجموع سایز ها" };
    final.push(sizeSum);
    console.log("final : ", final);
    exportToCSV(final, null , "sells data");
  };

  return (
    <div className="mainComponent">
      <div>
        <p>ایندکس خانه اکسل</p>
        <div style={{display: "flex", flexDirection:"row-reverse" , justifyContent:"center", alignItems:"center"}} >
          <p>آیا مقدار فروش دارد؟</p>
        <input defaultChecked={hasCount} value={hasCount} onChange={(e)=>setHasCount(e.target.checked)} type="checkbox" />
        </div>
        <div style={{display: "flex", justifyContent:"center", alignItems:"center", gap:"40px"}}>
          <div>
            <p>barcode index</p>
            <input value={barcode} onChange={(e)=> setBarcode(e.target.value) } type="number" style={{width: "50px "}} />
          </div>
          <div>
            <p>title index</p>
            <input value={oldTitleInd} onChange={(e)=> setOldTitleInd(e.target.value) } type="number" style={{width: "50px "}} />
          </div>
          {hasCount ? 
          <div>
            <p>count index</p>
            <input value={countInd} onChange={(e)=> setCountInd(e.target.value) } type="number" style={{width: "50px "}} />
          </div>
          :<>
          <div>
            <p>sell index</p>
            <input value={sellInd} onChange={(e)=> setSellInd(e.target.value) } type="number" style={{width: "50px "}} />
          </div>
          <div>
            <p>back index</p>
            <input value={backInd} onChange={(e)=> setBackInd(e.target.value) } type="number" style={{width: "50px "}} />
          </div>
          </>}
        </div>
      </div>
      <div>
        <FileInput
          title="فایل گزارش فروش به تفکیک کالا"
          fileName="hesabfa"
          contextData={setFileDatas}
        />
        <div>
          <button onClick={submit}>تایید</button>
        </div>
      </div>
    </div>
  );
};

export default SellData;
