import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/provider";
import { exportToCSV, FileInput } from "../mostlyUsed/mainComponents";

const Shops = () => {
  const { fileDatas, setFileDatas, verifiedCodes } = useContext(UserContext);

  const [shopSelected, setShopSelected] = useState("digikala");
  const [optionSelected, setOptionSelected] = useState("");

  useEffect(() => {
    console.log(optionSelected);
  }, [optionSelected]);

  const shopChange = (e) => {
    setShopSelected(e.target.value);
    console.log(e.target.value);
  };

  const optionChange = (e) => {
    console.log(e);
    setOptionSelected((val) => {
      return { ...val, [e.target.value]: e.target.checked };
    });
  };

  const submit = () => {
    console.log(fileDatas);
    const mainCountInd = 17
    const mainCodeInd = 4

    if (shopSelected === "digikala") {
      for (let i in fileDatas.anbar) {
        let barcodeInd = 4;
        let priceInd = 7;
        let countInd = 12;
        if (i !== "0") {
          for (let g in fileDatas.hesabfa) {
            if (
              fileDatas.anbar[i][barcodeInd] === Number(fileDatas.hesabfa[g][mainCodeInd])
            ) {
              if (!!optionSelected.price) {
                console.log("here");
                console.log(
                  fileDatas.anbar[i][barcodeInd],
                  fileDatas.hesabfa[g][4]
                );
                console.log(fileDatas.anbar[i][priceInd]);
                console.log(fileDatas.hesabfa[g]);
                fileDatas.anbar[i][priceInd] = Number(fileDatas.hesabfa[g][17]);
                console.log(fileDatas.anbar[i][priceInd]);
              }
              if (!!optionSelected.count) {
                console.log(fileDatas.anbar[i][countInd] , fileDatas.hesabfa[g][mainCountInd]);
                fileDatas.anbar[i][countInd] = Number(fileDatas.hesabfa[g][mainCountInd]);
              }
            }
          }
        }
      }
    } else if (shopSelected === "modiseh") {
      for (let i in fileDatas.anbar) {
        let barcodeInd = 4;
        let sizeInd = 2;
        let priceInd = 7;
        let countInd = 5;
        let showInSiteInd = 10;
        if (i !== "0") {
          for (let g in fileDatas.hesabfa) {
            if (
              Number(fileDatas.anbar[i][barcodeInd] + fileDatas.anbar[i][sizeInd]) === Number(fileDatas.hesabfa[g][mainCodeInd])
            ) {
              if (!!optionSelected.price) {
                console.log("here");
                console.log(
                  fileDatas.anbar[i][barcodeInd],
                  fileDatas.hesabfa[g][4]
                );
                console.log(fileDatas.anbar[i][priceInd]);
                console.log(Number(fileDatas.hesabfa[g][17]));
                fileDatas.anbar[i][priceInd] = Number(fileDatas.hesabfa[g][17]);
                // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                console.log(fileDatas.anbar[i][priceInd]);
              }
              if (!!optionSelected.count) {
                fileDatas.anbar[i][countInd] = Number(fileDatas.hesabfa[g][mainCountInd])
                fileDatas.anbar[i][showInSiteInd] = (fileDatas.anbar[i][countInd] === 0 ? 0 : 1)
              }
            }
          }
        }
      }
    }
    // console.log(fileDatas.anbar);
    exportToCSV(fileDatas.anbar, null,  `${shopSelected} warehouse update`);
  };

  return (
    <div className="mainComponent">
      <div style={{ direction: "rtl" }} onChange={(e) => shopChange(e)}>
        <p>:فروشگاهی که فایل آن را آپلود می کنید</p>
        دیجیکالا
        <input type="radio" value="digikala" name="shop" defaultChecked style={{ marginLeft: 10 }} />
        مدیسه <input type="radio" value="modiseh" name="shop" />
        {/* <input type="radio" value="Other" name="gender" /> Other */}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FileInput
          title="فایل اکسل انبار حسابفا"
          fileName="hesabfa"
          contextData={setFileDatas}
        />
        <FileInput
          title="فایل اکسل فروشگاه مورد نظر "
          fileName="anbar"
          contextData={setFileDatas}
          digikala={shopSelected === "digikala" ? true: false}
        />
      </div>
      <div style={{ direction: "rtl" }} onChange={(e) => optionChange(e)}>
        <p>مواردی که می خواهید اعمال شود</p>
        قیمت{" "}
        <input
          type="checkbox"
          value="price"
          name="option"
          style={{ marginLeft: 10 }}
        />
        تعداد <input type="checkbox" value="count" name="option" />
        {/* <input type="radio" value="Other" name="gender" /> Other */}
      </div>
      <div>
        <button onClick={submit}>تایید</button>
      </div>
    </div>
  );
};

export default Shops;
