import { useContext } from "react";
import { UserContext } from "../../context/provider";
import { exportToCSV, FileInput } from "../mostlyUsed/mainComponents";

const FullData = () => {
  const { fileDatas, setFileDatas, verifiedCodes } = useContext(UserContext);

  const submit = () => {
    let allClearProducts = []
    let mainResualt = []
    for (let i in fileDatas.hesabfa) {
      if ((i !== "0") && (verifiedCodes.includes(fileDatas.hesabfa[i][4]?.substring(0, 2))) && (fileDatas.hesabfa[i][4].length === 11)) {
        let oldTitle = fileDatas.hesabfa[i][2]
        let title = ""
        // if (oldTitle.includes("MA")) { title = oldTitle.replace("MA", "") } else if (oldTitle.includes("WA")) { title = oldTitle.replace("WA", "") } else if (oldTitle.includes("KA")) { title = oldTitle.replace("KA", "") } else if (oldTitle.includes("M")) { title = oldTitle.replace("M", "") } else if (oldTitle.includes("W")) { title = oldTitle.replace("W", "") } else if (oldTitle.includes("K")) { title = oldTitle.replace("K", "") }
        allClearProducts.push({ "count": fileDatas.hesabfa[i][26], "code": fileDatas.hesabfa[i][4], "productCode": fileDatas.hesabfa[i][4].slice(0, 9), "title": oldTitle })
        mainResualt.push({ "count": 0, "code": fileDatas.hesabfa[i][4], "productCode": fileDatas.hesabfa[i][4].slice(0, 9) , "title": oldTitle })
      }
    }
    console.log("allClearProducts : ", allClearProducts);
    console.log("mainResualt : ", mainResualt);
    console.log(fileDatas);
    for (let i in mainResualt) {
      for (let j of fileDatas.anbar) {
        if (mainResualt[i].code === String(j[0])) {
          mainResualt[i].count = mainResualt[i].count + 1
        }
      }
    }

    console.log("new one : ", mainResualt);

    let final = []
    let pushed = []
    for (let i in mainResualt) {
      if (pushed.includes(mainResualt[i].productCode)) {
        for (let g in final) {
          if (mainResualt[i].productCode === final[g].productCode) {
            const size = mainResualt[i].code.substring(9, 11)
            final[g][size] = mainResualt[i].count
            break
          }
        }
      } else {
        console.log(mainResualt[i]);
        const size = mainResualt[i].code.substring(9, 11)
        console.log(size);
        pushed.push(mainResualt[i].productCode)
        final.push({ title: mainResualt[i].title, productCode: mainResualt[i].productCode , "30": "", "31": "", "32": "", "33": "", "34": "", "35": "", "36": "", "37": "", "38": "", "39": "", "40": "", "41": "", "42": "", "43": "", "44": "", "45": "", "46": "", "47": "" })
        final[final.length - 1][size] = mainResualt[i].count
      }
    }
    let sizeSum = {}
    for (let i in final) {
      final[i].title = final[i].title.replace(/\s+/g, ' ').trim()
      console.log("---------------------")
      for (let j = 30; j < 48; j++) {
        console.log(j, ": ", final[i][j])
        if (!sizeSum[j]) {
          sizeSum[j] = 0
        }
        sizeSum[j] += (final[i][j] ? final[i][j] : 0)
      }
    }
    final.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
    sizeSum = { ...sizeSum, title: "مجموع سایز ها" }
    final.push(sizeSum)
    console.log("final : ", final);
    exportToCSV(final, null, "anbar")
  }

  return (
    <div className="mainComponent">
      <FileInput
        title="فایل اکسل انبار حسابفا"
        fileName="hesabfa"
        contextData={setFileDatas}
      />
      <FileInput
        title="فایل اکسل بارکد های انبار"
        fileName="anbar"
        contextData={setFileDatas}
      />
      <div>
        <button onClick={submit}>تایید</button>
      </div>
    </div>
  );
};

export default FullData;
