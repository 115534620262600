import { useContext, useState } from "react";
import SellLong from "./sellLong";
import { UserContext } from "../../context/provider";
import SellLongCalculator from "../mostlyUsed/sellLongCalculator";
import axios from "axios";
import { dummyLongData } from "./test";
import { exportToCSV, ManyFileInput } from "../mostlyUsed/mainComponents";

const ProductionCount = () => {
  const [proccessState, setProccessState] = useState(0);
  const [proccessText, setProccessText] = useState("");
  const [fileDatas, setFileDatas] = useState([]);
  const { verifiedCodes } = useContext(UserContext);
  let sellLong = [];
  let finalData = [];

  const gradeLimit = 60;
  const minProductionQuantity = 20;

  const groups = {
    kids: {
      from: 25,
      to: 29,
      factor: {
        25: 2,
        26: 2,
        27: 2,
        28: 2,
        29: 2,
      },
      fillOrder: [
        25,26,27,28,29,25,26,27,28,29,25,26,27,28,29
      ],
      minProduction: { 1: 6, 2: 12 },
    },
    child: {
      from: 30,
      to: 34,
      factor: {
        30: 2,
        31: 2,
        32: 2,
        33: 1,
        34: 1,
      },
      fillOrder: [
        30,31,32,33,34,30,31,32,33,34,30,31,32,33,34,30,31,32,33,34
      ],
      minProduction: { 1: 5, 2: 11 },
    },
    female: {
      from: 35,
      to: 40,
      factor: {
        35: 1,
        36: 3,
        37: 4,
        38: 5,
        39: 4,
        40: 3,
      },
      fillOrder: [38, 37, 39, 36, 40, 38, 37, 39, 35, 36, 40],
      minProduction: { 1: 12, 2: 18 },
    },
    male: {
      from: 41,
      to: 45,
      factor: {
        41: 3,
        42: 3,
        43: 2,
        44: 1,
        45: 1,
      },
      fillOrder: [41, 42, 43, 44, 45, 41, 42, 43, 44, 45, 41, 42, 43, 41, 42],
      minProduction: { 1: 6, 2: 12 },
    },
  };

  const arraySum = (array) => {
    const sum = array.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    return sum;
  };

  const getQuantity = async () => {
    const res = await axios.post(
      "https://api.hesabfa.com/v1/item/GetQuantity",
      {
        apiKey: "E62BNpYDHmn1K8JFNJG24jDSpTE9ktBP",
        loginToken:
          "3849304732a5e3cfd84a908ffd0b8c1c4e44c804df3692627b127d3f5638d3791105a1df1ddd2f00cb6bf2f36c3d3827",
        warehouseCode: 11,
      }
    );
    console.log(res);
    let filtered = res.data.Result.filter(
      (e) =>
        verifiedCodes.includes(e.Barcode?.substring(0, 2)) &&
        e.Barcode.length === 11
    );

    return filtered;
  };

  const addOldQuantitys = (quantityList) => {
    console.log("old quantity: ",quantityList);
    if(fileDatas.length > 0){
        for(let sheet of fileDatas){
            for(let product in sheet){
                if(product !== 0){
                    for(let size = 0; size <= 20; size++){
                        const index = quantityList.findIndex(e => e.Barcode === (sheet[product][22] + sheet[0][size]))
                        if(index >= 0){
                            console.log( sheet[product][22] , sheet[0][size]);
                            console.log("index: ", index, "product: ", product);
                            console.log(quantityList[index].Quantity , sheet[product][size]);
                            quantityList[index].Quantity = quantityList[index].Quantity + sheet[product][size]
                        }
                    }
                }
            }
        }
    }
    console.log("new quantity: ",quantityList);
    return quantityList
  }

  const addQuantityToData = (filledQuantity) => {
    for(let i in sellLong[1]){
        const qIndex = filledQuantity.findIndex(e => e.Code === sellLong[1][i].code)
        sellLong[1][i].quantity = qIndex >= 0 ? filledQuantity[qIndex].Quantity : "-"
    }
  }

  const CalculateProduction = (sum, count, i, product, sex, quantityList) => {
    const average = sum / count;
    const grade = average > gradeLimit ? 1 : average > 15 ? 2 : 1;

    let correctBarcode = "";

    let groupQuantity = {
      25: null,
      26: null,
      27: null,
      28: null,
      29: null,
      30: null,
      31: null,
      32: null,
      33: null,
      34: null,
      35: null,
      36: null,
      37: null,
      38: null,
      39: null,
      40: null,
      41: null,
      42: null,
      43: null,
      44: null,
      45: null,
    };

    for (let g = groups[sex].from; g <= groups[sex].to; g++) {
      const code = sellLong[1].filter((e) => e.barcode === product.code + g)[0]?.code;

      // calculates needed quantity of this model
      const liveQuantity = quantityList.filter((e) => e.Code === code)[0];
      correctBarcode = liveQuantity?.barcode?.substring(0, 9);
      if (liveQuantity) {
        const needQuantity =
          groups[sex].factor[g] * grade - liveQuantity.Quantity;
        groupQuantity[g] = needQuantity <= 0 ? 0 : needQuantity;
      }
    }

    const totalCountList = Object.values(groupQuantity);
    const totalCount = arraySum(totalCountList);

    console.log("old quantity: ", groupQuantity);

    if (totalCount > groups[sex].minProduction[grade]) {
      let addQuantityIndex = 0;
      while (arraySum(Object.values(groupQuantity)) < minProductionQuantity) {
        if (
          minProductionQuantity - arraySum(Object.values(groupQuantity)) >
          arraySum(Object.values(groups[sex].factor))
        ) {
          // here will add 1 round of series
          for (let i = groups[sex].from; i <= groups[sex].to; i++) {
            const code = sellLong[1].filter((e) => e.barcode === product.code + i)[0]?.code;
            const liveQuantity = quantityList.filter((e) => e.Code === code)[0];
            if (!(liveQuantity?.Quantity > (groups[sex].factor[i] * 3))) {
              if (groupQuantity[i]) {
                groupQuantity[i] = groupQuantity[i] + groups[sex].factor[i];
              } else {
                groupQuantity[i] = groups[sex].factor[i];
              }
            }
          }
        } else {
          let isAdded = false;
          // for(let i = groups[sex].from; i <= groups[sex].to; i++){
          //     if(!groupQuantity[i]){
          //         groupQuantity[i] = 1
          //         isAdded = true
          //         break
          //     }
          // }
          if (!isAdded) {
            const thisSize = groups[sex].fillOrder[addQuantityIndex]
            const code = sellLong[1].filter((e) => e.barcode === product.code + thisSize)[0]?.code;
            const liveQuantity = quantityList.filter((e) => e.Code === code)[0];
            console.log("live ", liveQuantity, groups[sex].factor[thisSize], thisSize);
            if (!(liveQuantity?.Quantity > (groups[sex].factor[i] * 3))) {
                groupQuantity[thisSize] = groupQuantity[thisSize] + 1;
            }
            addQuantityIndex = addQuantityIndex + 1;
          }
        }
      }

      const oneUniqCode = sellLong[1].filter((e) =>e.barcode.includes(product.code))[0].code;
      const correctBarcode = quantityList.filter((e) => e.Code === oneUniqCode)[0] ? quantityList.filter((e) => e.Code === oneUniqCode)[0].Barcode : product.code;

      addQuantityIndex = 0;
      groupQuantity.title = product.title;
      groupQuantity.code = correctBarcode?.substring(0, 9);
      groupQuantity.sellLong = average ? Math.round(average) : 0;
      groupQuantity.grade = grade;
      console.log("this code: ", groupQuantity.code);
      console.log("new quantity: ", groupQuantity);
      console.log("-----------------------------------");
      finalData.push(groupQuantity);
    }

    // console.log(
    //   i,
    //   "group: ",
    //   sex,
    //   "product: ",
    //   product.title,
    //   "grade: ",
    //   grade,
    //   "groupQuantity: ",
    //   groupQuantity
    // );

    // console.log(totalCount);
  };

  const CalculateDatas = (quantityList) => {
    console.log("sell single", sellLong[1]);
    console.log(sellLong[0]);
    for (let product of sellLong[0]) {
      let sum = 0;
      let count = 0;
      for (let i = groups.kids.from; i <= groups.male.to; i++) {
        if (!!product[i] && product[i] !== "-") {
          sum = sum + product[i];
          count = count + 1;
        }

        if (groups.kids.to === i) {
          //kids group

          CalculateProduction(sum, count, i, product, "kids", quantityList);
          sum = 0;
          count = 0;
        }
        if (groups.child.to === i) {
          //child group

          CalculateProduction(sum, count, i, product, "child", quantityList);
          sum = 0;
          count = 0;
        }
        if (groups.female.to === i) {
          //female group

          CalculateProduction(sum, count, i, product, "female", quantityList);

          sum = 0;
          count = 0;
        }
        if (groups.male.to === i) {
          //male group

          CalculateProduction(sum, count, i, product, "male", quantityList);

          sum = 0;
          count = 0;
        }
      }
    }
  };

  const startAction = async () => {
    const cleanedData = await SellLongCalculator(
      setProccessState,
      setProccessText
    );
    console.log("cleaned data: ", cleanedData);
    // localStorage.setItem("cleanedData", JSON.stringify(cleanedData));
    // const cleanedData = JSON.parse(localStorage.getItem("cleanedData"));

    console.log(cleanedData);

    sellLong = cleanedData;

    setProccessText("در حال دریافت موجودی فعلی");
    // setProccessText("در حال محاسبه میزان مناسب تولید");
    setProccessState(80);
    const quantityList = await getQuantity();
    console.log("quantity List: ", quantityList);
    // localStorage.setItem("quantityList", JSON.stringify(quantityList));

    // const quantityList = JSON.parse(localStorage.getItem("quantityList"));

    const filledQuantity = addOldQuantitys(quantityList)

    console.log("filledQuantity", filledQuantity);

    CalculateDatas(filledQuantity);
    finalData.sort((a, b) => {
      if (a.sellLong < 12) return 1;
      if (b.sellLong < 12) return -1;
      return a.sellLong - b.sellLong;
    });

    addQuantityToData(filledQuantity)

    setProccessText("فایل آماده است");
    setProccessState(100);
    console.log("final data: ", finalData);
    console.log("sellLong: ", sellLong[1]);
    exportToCSV(finalData, sellLong[1], "sells");
  };

  return (
    <div>
      <button
        style={{
          backgroundColor: "white",
          border: "solid 1px gray",
          padding: 10,
          borderRadius: 10,
          cursor: "pointer",
          fontSize: 15,
        }}
        onClick={startAction}
      >
        شروع ایجاد فایل
      </button>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 20,
        }}
      >
        <div
          style={{
            width: "80%",
            height: 20,
            backgroundColor: "#dbdbdb",
            borderRadius: 15,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              width: `${proccessState}%`,
              height: 20,
              backgroundColor: "#00a600",
              borderRadius: 15,
              transition: "all 2s ease-in-out",
            }}
          ></div>
        </div>
        <p>{proccessText}</p>
      </div>
      <ManyFileInput
        title="فایل اکسل انبار حسابفا"
        fileName="hesabfa"
        contextData={setFileDatas}
      />
    </div>
  );
};

export default ProductionCount;
