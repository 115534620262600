import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./context/provider";
import logo from "./logo.svg";
import "./App.css";
import readXlsxFile from "read-excel-file";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BoxHandler from "./components/boxHandler";

const contents = [
  "فروشگاه ها",
  "آمار فروش",
  "انبار گردانی",
  "اطلاعات کامل",
  "طول فروش",
  "آمار تولید"
];
const verifiedCodes = ["11", "22", "33", "55", "66", "44"];

function App() {
  const { position, setPosition } = useContext(UserContext);
  const [clicked, setClicked] = useState("");

  const handleClick = (item) => {
    setPosition(item);
    console.log(item);
  };

  const fileDatas = {};

  const uploaded = (e, val) => {
    console.log(e);
    readXlsxFile(e.target.files[0]).then((rows) => {
      fileDatas[val] = rows;
    });
  };

  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const anbarGardani = () => {
    let allClearProducts = [];
    let mainResualt = [];
    for (let i in fileDatas.hesabfa) {
      if (
        i !== "0" &&
        verifiedCodes.includes(fileDatas.hesabfa[i][4]?.substring(0, 2)) &&
        fileDatas.hesabfa[i][4].length === 11
      ) {
        allClearProducts.push({
          count: fileDatas.hesabfa[i][26],
          code: fileDatas.hesabfa[i][4],
          title: fileDatas.hesabfa[i][2],
        });
        mainResualt.push({
          count: 0,
          code: fileDatas.hesabfa[i][4],
          title: fileDatas.hesabfa[i][2],
        });
      }
    }
    console.log("allClearProducts : ", allClearProducts);
    console.log("mainResualt : ", mainResualt);
    console.log(fileDatas);
    for (let i in mainResualt) {
      for (let j of fileDatas.anbar) {
        if (mainResualt[i].code === String(j[0])) {
          mainResualt[i].count = mainResualt[i].count + 1;
        }
      }
    }

    console.log("new one : ", mainResualt);

    let conflict = [];
    for (let i in mainResualt) {
      for (let j in allClearProducts) {
        if (
          mainResualt[i]["code"] === allClearProducts[j]["code"] &&
          mainResualt[i]["count"] - allClearProducts[j]["count"] != 0
        ) {
          conflict.push({
            اختلاف: mainResualt[i]["count"] - allClearProducts[j]["count"],
            "تعداد در انبار": mainResualt[i]["count"],
            "تعداد در سیستم": allClearProducts[j]["count"],
            سایز: String(mainResualt[i]["code"]).substring(9, 11),
            بارکد: mainResualt[i]["code"],
            "نام محصول": mainResualt[i]["title"],
          });
        }
      }
    }
    console.log("conflicts : ", conflict);
    exportToCSV(conflict, "final file");
  };

  const shopsExell = () => {
    console.log(fileDatas);
  };

  const vayjooeiData = () => {
    let allClearProducts = [];
    let mainResualt = [];
    for (let i in fileDatas.hesabfa) {
      if (
        i !== "0" &&
        verifiedCodes.includes(fileDatas.hesabfa[i][4]?.substring(0, 2)) &&
        fileDatas.hesabfa[i][4].length === 11
      ) {
        let oldTitle = fileDatas.hesabfa[i][2];
        let title = "";
        if (oldTitle.includes("MA")) {
          title = oldTitle.replace("MA", "");
        } else if (oldTitle.includes("WA")) {
          title = oldTitle.replace("WA", "");
        } else if (oldTitle.includes("KA")) {
          title = oldTitle.replace("KA", "");
        } else if (oldTitle.includes("M")) {
          title = oldTitle.replace("M", "");
        } else if (oldTitle.includes("W")) {
          title = oldTitle.replace("W", "");
        } else if (oldTitle.includes("K")) {
          title = oldTitle.replace("K", "");
        }
        allClearProducts.push({
          count: fileDatas.hesabfa[i][26],
          code: fileDatas.hesabfa[i][4],
          title: title,
        });
        mainResualt.push({
          count: 0,
          code: fileDatas.hesabfa[i][4],
          title: title,
        });
      }
    }
    console.log("allClearProducts : ", allClearProducts);
    console.log("mainResualt : ", mainResualt);
    console.log(fileDatas);
    for (let i in mainResualt) {
      for (let j of fileDatas.anbar) {
        if (mainResualt[i].code === String(j[0])) {
          mainResualt[i].count = mainResualt[i].count + 1;
        }
      }
    }

    console.log("new one : ", mainResualt);

    let final = [];
    let pushed = [];
    for (let i in mainResualt) {
      if (pushed.includes(mainResualt[i].title)) {
        for (let g in final) {
          if (mainResualt[i].title === final[g].title) {
            const size = mainResualt[i].code.substring(9, 11);
            final[g][size] = mainResualt[i].count;
            break;
          }
        }
      } else {
        console.log(mainResualt[i]);
        const size = mainResualt[i].code.substring(9, 11);
        console.log(size);
        pushed.push(mainResualt[i].title);
        final.push({
          title: mainResualt[i].title,
          30: "",
          31: "",
          32: "",
          33: "",
          34: "",
          35: "",
          36: "",
          37: "",
          38: "",
          39: "",
          40: "",
          41: "",
          42: "",
          43: "",
          44: "",
          45: "",
          46: "",
          47: "",
        });
        final[final.length - 1][size] = mainResualt[i].count;
      }
    }
    let sizeSum = {};
    for (let i in final) {
      final[i].title = final[i].title.replace(/\s+/g, " ").trim();
      console.log("---------------------");
      for (let j = 30; j < 48; j++) {
        console.log(j, ": ", final[i][j]);
        if (!sizeSum[j]) {
          sizeSum[j] = 0;
        }
        sizeSum[j] += final[i][j] ? final[i][j] : 0;
      }
    }
    final.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    sizeSum = { ...sizeSum, title: "مجموع سایز ها" };
    final.push(sizeSum);
    console.log("final : ", final);
    exportToCSV(final, "anbar");
  };

  const sellsData = () => {
    console.log(fileDatas.hesabfa);
    let mainResualt = [];
    for (let i in fileDatas.hesabfa) {
      if (
        i !== "0" &&
        verifiedCodes.includes(fileDatas.hesabfa[i][3]?.substring(0, 2)) &&
        fileDatas.hesabfa[i][3].length === 11
      ) {
        let oldTitle = fileDatas.hesabfa[i][2];
        let title = "";
        if (oldTitle.includes("MA")) {
          title = oldTitle.replace("MA", "");
        } else if (oldTitle.includes("WA")) {
          title = oldTitle.replace("WA", "");
        } else if (oldTitle.includes("KA")) {
          title = oldTitle.replace("KA", "");
        } else if (oldTitle.includes("M")) {
          title = oldTitle.replace("M", "");
        } else if (oldTitle.includes("W")) {
          title = oldTitle.replace("W", "");
        } else if (oldTitle.includes("K")) {
          title = oldTitle.replace("K", "");
        }
        mainResualt.push({
          count: fileDatas.hesabfa[i][5] - fileDatas.hesabfa[i][7],
          code: fileDatas.hesabfa[i][3],
          title: title,
        });
      }
    }
    console.log("main resault: ", mainResualt);

    let final = [];
    let pushed = [];
    for (let i in mainResualt) {
      if (pushed.includes(mainResualt[i].title)) {
        for (let g in final) {
          if (mainResualt[i].title === final[g].title) {
            const size = mainResualt[i].code.substring(9, 11);
            final[g][size] = mainResualt[i].count;
            break;
          }
        }
      } else {
        console.log(mainResualt[i]);
        const size = mainResualt[i].code.substring(9, 11);
        console.log(size);
        pushed.push(mainResualt[i].title);
        final.push({
          title: mainResualt[i].title,
          30: "",
          31: "",
          32: "",
          33: "",
          34: "",
          35: "",
          36: "",
          37: "",
          38: "",
          39: "",
          40: "",
          41: "",
          42: "",
          43: "",
          44: "",
          45: "",
          46: "",
          47: "",
        });
        final[final.length - 1][size] = mainResualt[i].count;
      }
    }
    let sizeSum = {};
    for (let i in final) {
      final[i].title = final[i].title.replace(/\s+/g, " ").trim();
      console.log("---------------------");
      for (let j = 30; j < 48; j++) {
        console.log(j, ": ", final[i][j]);
        if (!sizeSum[j]) {
          sizeSum[j] = 0;
        }
        sizeSum[j] += final[i][j] ? final[i][j] : 0;
      }
    }
    final.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    sizeSum = { ...sizeSum, title: "مجموع سایز ها" };
    final.push(sizeSum);
    console.log("final : ", final);
    exportToCSV(final, "sells data");
  };

  const submit = () => {
    console.log(Object.keys(fileDatas).length);
    if (position === "آمار فروش") {
      if (Object.keys(fileDatas).length === 1) {
        sellsData();
      } else {
        alert("لطفا هر دو مورد را وارد نمایید");
      }
    } else {
      if (Object.keys(fileDatas).length < 2) {
        alert("لطفا هر دو مورد را وارد نمایید");
      } else if (Object.keys(fileDatas).length === 2) {
        if (position === "انبار گردانی") {
          anbarGardani();
        } else if (position === "اطلاعات کامل") {
          vayjooeiData();
        } else if (position === "فروشگاه ها") {
          shopsExell();
        }
      }
    }
  };

  return (
    <div className="App">
      <div className="header">
        {contents.map((item, index) => {
          return (
            <div
              key={index}
              style={position === item ? { backgroundColor: "#36da88" } : {}}
              className="content"
              onClick={() => handleClick(item)}
            >
              {item}
            </div>
          );
        })}
      </div>
      {/* {clicked === "آمار فروش" ? (
        <div className="mainComponent">
          <div>
            <p>فایل اکسل انبار حسابفا</p>
            <input onChange={(e) => uploaded(e, "hesabfa")} type="file" />
          </div>
          <div>
            <button onClick={submit} >تایید</button>
          </div>
        </div>
      ) : (
        <div className="mainComponent">
          <div>
            <p>فایل اکسل انبار حسابفا</p>
            <input onChange={(e) => uploaded(e, "hesabfa")} type="file" />
          </div>
          <div>
            <p>فایل اکسل بارکد های انبار</p>
            <input onChange={(e) => uploaded(e, "anbar")} type="file" />
          </div>
          <div>
            <button onClick={submit} >تایید</button>
          </div>
        </div>
      )} */}
      <BoxHandler />
    </div>
  );
}

export default App;
