import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/provider";
import { exportToCSV } from "../mostlyUsed/mainComponents";
import sellLongCalculator from "../mostlyUsed/sellLongCalculator";

const countTerm = 500;

const SellLong = ({}) => {
  const [proccessState, setProccessState] = useState(0);
  const [proccessText, setProccessText] = useState("");
  const { verifiedCodes } = useContext(UserContext);

  // const getReceipt = async (skip, type, year) => {
  //   const res = await axios.post(
  //     "https://api.hesabfa.com/v1/warehouse/GetReceipts",
  //     {
  //       apiKey: "E62BNpYDHmn1K8JFNJG24jDSpTE9ktBP",
  //       loginToken:
  //         "3849304732a5e3cfd84a908ffd0b8c1c4e44c804df3692627b127d3f5638d3791105a1df1ddd2f00cb6bf2f36c3d3827",
  //       type: type,
  //       yearId: year,
  //       queryInfo: {
  //         SortBy: "Date",
  //         SortDesc: false,
  //         Take: countTerm,
  //         skip: skip * countTerm,
  //         Filters: [
  //           {
  //             Property: "WarehouseCode",
  //             Operator: "=",
  //             Value: 11,
  //           },
  //         ],
  //       },
  //     }
  //   );
  //   return res;
  // };

  // const getAll = async (type) => {
  //   let allBuys = [];
  //   for (let i = 2; i < 5; i++) {
  //     setProccessState(e=>e+10)
  //     let getState = 0;
  //     let allDatasCount = countTerm + 1;
  //     do {
  //       const data = await getReceipt(getState, type, i);
  //       if (data.data.Success) {
  //         allDatasCount = data.data.Result.FilteredCount;
  //         console.log("type: ", type, "state " + getState, "data: ", data);
  //         getState = getState + 1;
  //         allBuys.push(...data.data.Result.List);
  //       }
  //     } while (getState < Math.ceil(allDatasCount / countTerm));
  //   }
  //   console.log(allBuys);
  //   return allBuys;
  // };

  // const differenceTime = (date1, date2) => {
  //   let Difference_In_Time = date2.getTime() - date1.getTime();

  //   // Calculating the no. of days between
  //   // two dates
  //   let Difference_In_Days = Math.round(
  //     Difference_In_Time / (1000 * 3600 * 24)
  //   );

  //   return Difference_In_Days;
  // };

  // // const getAllSells = async () => {
  // //   let getState = 0;
  // //   let allDatasCount = countTerm + 1;
  // //   let allBuys = [];
  // //   do {
  // //     const data = await getBuy(getState);
  // //     if (data.status === 200) {
  // //       allDatasCount = data.data.Result.FilteredCount;
  // //       console.log("state " + getState, "data: ", data);
  // //       getState = getState + 1;
  // //       allBuys.push(...data.data.Result.List);
  // //     }
  // //   } while (getState < Math.ceil(allDatasCount / countTerm));
  // //   console.log(allBuys);
  // //   setBuysData(allBuys);
  // // };

  // const startAction = async () => {
  //   setProccessText("در حال دریافت اطلاعات خرید")
  //   const allBuys = await getAll(1);
  //   setProccessText("در حال دریافت اطلاعات فروش")
  //   const allSells = await getAll(2);
  //   setProccessText("در حال دریافت محاسبه")
  //   let cleanedData = [];
  //   for (let buy of allBuys) {
  //     for (let buyItem of buy.Items) {
  //       const existInd = cleanedData.findIndex(
  //         (e) => e.code === buyItem.ItemCode
  //       );
  //       if (existInd >= 0) {
  //         for (let i = 1; i <= buyItem.Quantity; i++) {
  //           cleanedData[existInd].data.in.push(new Date(buy.Date));
  //         }
  //         cleanedData[existInd].name = buyItem.Item.Name;
  //         cleanedData[existInd].barcode = buyItem.Item.Barcode;
  //       } else {
  //         if (buy.InvoiceType > 0) {
  //           let items = [];
  //           for (let i = 1; i <= buyItem.Quantity; i++) {
  //             items.push(new Date(buy.Date));
  //           }
  //           cleanedData.push({
  //             code: buyItem.ItemCode,
  //             barcode: buyItem.Item.Barcode,
  //             name: buyItem.Item.Name,
  //             days: 0,
  //             data: { in: items, out: [] },
  //           });
  //         }
  //       }
  //     }
  //   }

  //   for (let sell of allSells) {
  //     for (let sellItem of sell.Items) {
  //       const existInd = cleanedData.findIndex(
  //         (e) => e.code === sellItem.ItemCode
  //       );
  //       if (existInd >= 0) {
  //         cleanedData[existInd].data.out.push(new Date(sell.Date));
  //       }
  //     }
  //   }

  //   for (let i in cleanedData) {
  //     let day = 0;
  //     let count = 0;
  //     for (let j in cleanedData[i].data.out) {
  //       if (cleanedData[i].data.out[j] > cleanedData[i].data.in[j]) {
  //         day =
  //           day +
  //           differenceTime(
  //             cleanedData[i].data.in[j],
  //             cleanedData[i].data.out[j]
  //           );
  //         count = count + 1;
  //       }
  //     }
  //     const avrage = day / count;
  //     cleanedData[i].days = Math.round(avrage);
  //   }
  //   console.log("cleaned data: ", cleanedData);

  //   let tabledDatas = [];
  //   let tabledDatas2 = [];

  //   for (let i in cleanedData) {
  //     if (
  //       verifiedCodes.includes(cleanedData[i].barcode?.substring(0, 2)) &&
  //       cleanedData[i].barcode.length === 11
  //     ) {
  //       const pureCode = cleanedData[i].barcode?.substring(0, 9);
  //       const size = cleanedData[i].barcode?.substring(9, 11);

  //       tabledDatas2.push({
  //         barcode: cleanedData[i].barcode,
  //         code: cleanedData[i].code,
  //         title: cleanedData[i].name,
  //         size: size,
  //         days: cleanedData[i].days ? cleanedData[i].days : "-",
  //         sell: cleanedData[i].data.out.length,
  //         buy: cleanedData[i].data.in.length,
  //       });

  //       const tableIndex = tabledDatas.findIndex((e) => e.code === pureCode);

  //       if (tableIndex >= 0) {
  //         tabledDatas[tableIndex][size] = cleanedData[i].days
  //           ? cleanedData[i].days
  //           : "-";
  //       } else {
  //         tabledDatas.push({
  //           title: cleanedData[i].name,
  //           code: pureCode,
  //           25: "",
  //           26: "",
  //           27: "",
  //           28: "",
  //           29: "",
  //           30: "",
  //           31: "",
  //           32: "",
  //           33: "",
  //           34: "",
  //           35: "",
  //           36: "",
  //           37: "",
  //           38: "",
  //           39: "",
  //           40: "",
  //           41: "",
  //           42: "",
  //           43: "",
  //           44: "",
  //           45: "",
  //           46: "",
  //           47: "",
  //         });
  //         tabledDatas[tabledDatas.length - 1][size] = cleanedData[i].days
  //           ? cleanedData[i].days
  //           : "-";
  //       }
  //     }
  //   }

  //   console.log("table datas: ", tabledDatas);
  //   exportToCSV(tabledDatas, tabledDatas2, "sells long");
  //   setProccessState(100)
  //   setProccessText("انجام شد")
  // };

  const startAction = async () => {
    const cleanedData = await sellLongCalculator(setProccessState, setProccessText);
    exportToCSV(cleanedData[0], cleanedData[1], "sells long");
    setProccessState(100);
    setProccessText("انجام شد");
  };

  return (
    <div>
      <button
        style={{
          backgroundColor: "white",
          border: "solid 1px gray",
          padding: 10,
          borderRadius: 10,
          cursor: "pointer",
          fontSize: 15,
        }}
        onClick={startAction}
      >
        شروع ایجاد فایل
      </button>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 20,
        }}
      >
        <div
          style={{
            width: "80%",
            height: 20,
            backgroundColor: "#dbdbdb",
            borderRadius: 15,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              width: `${proccessState}%`,
              height: 20,
              backgroundColor: "#00a600",
              borderRadius: 15,
              transition: "all 2s ease-in-out",
            }}
          ></div>
        </div>
        <p>{proccessText}</p>
      </div>
    </div>
  );
};

export default SellLong;
