import readXlsxFile, { readSheetNames } from "read-excel-file";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import { UserContext } from "../../context/provider";
import { useContext } from "react";

const uploaded = (e, val, contextData, digikala) => {
  let fileNumber = digikala ? 2 : 1;
  console.log(e);
  readXlsxFile(e.target.files[0], { sheet: fileNumber }).then((rows) => {
    console.log(rows);
    contextData((e) => {
      return { ...e, [val]: rows };
    });
  });
};

const manySheetUploaded = (e, val, contextData, digikala) => {
  console.log(e);
  readSheetNames(e.target.files[0]).then((sheetNames) => {
    console.log(sheetNames);
    for (let sheet of sheetNames) {
      readXlsxFile(e.target.files[0], { sheet: sheet }).then((rows) => {
        console.log(sheet, rows);
        contextData((e) => {
          return [ ...e, rows ]
        });
      });
    }
  });
};

export const exportToCSV = (apiData, apiData2, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let wb = "";

  if (apiData2) {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const ws2 = XLSX.utils.json_to_sheet(apiData2);
    wb = {
      Sheets: { data: ws, moreData: ws2 },
      SheetNames: ["data", "moreData"],
    };
  } else {
    const ws = XLSX.utils.json_to_sheet(apiData);
    wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  }

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const FileInput = ({ title, fileName, contextData, digikala }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>{title}</p>
      <input
        onChange={(e) => uploaded(e, fileName, contextData, digikala)}
        type="file"
        accept=".xls,.xlsx"
      />
    </div>
  );
};

export const ManyFileInput = ({ title, fileName, contextData, digikala }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>{title}</p>
      <input
        onChange={(e) => manySheetUploaded(e, fileName, contextData, digikala)}
        type="file"
        accept=".xls,.xlsx"
      />
    </div>
  );
};
