import { useContext } from "react";
import { UserContext } from "../context/provider";
import FullData from "./boxes/fullData";
import SellData from "./boxes/sellData";
import Shops from "./boxes/shops";
import WarehouseHandling from "./boxes/warehouseHandling";
import SellLong from "./boxes/sellLong";
import ProductionCount from "./boxes/productionCount";
const BoxHandler = () => {
  const { position } = useContext(UserContext);
  
  switch(position){
      case "فروشگاه ها":
          return <Shops />
      case "آمار فروش":
          return <SellData />
      case "انبار گردانی":
          return <WarehouseHandling />
      case "اطلاعات کامل":
          return <FullData />
      case "طول فروش":
          return <SellLong />
      case "آمار تولید":
          return <ProductionCount />
  }
//   if(position === "فروشگاه ها"){

//   }else if(position === "آمار فروش"){
//       return(
//           <SellData />
//       )
//   }else if(position === "انبار گردانی"){

//   }else if(position === "اطلاعات کامل"){

//   }else{
//       return ""
//   }
  
};

export default BoxHandler;
